import { render, staticRenderFns } from "./CardInformationTraffic.vue?vue&type=template&id=57728162&scoped=true&"
import script from "./CardInformationTraffic.vue?vue&type=script&lang=js&"
export * from "./CardInformationTraffic.vue?vue&type=script&lang=js&"
import style0 from "./CardInformationTraffic.vue?vue&type=style&index=0&id=57728162&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "57728162",
  null
  
)

export default component.exports