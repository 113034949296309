<template>
  <article class="media">
    <div class="media-left">
      <ion-icon
        :style="`color: ${color};`"
        :name="icon"
      />
    </div>
    <div class="media-content">
      <div class="block">
        <p class="media_title">
          {{ title }}
        </p>
        <p class="media_total" :style="`color: ${color};`">
          {{ total }}
        </p>
      </div>
    </div>
  </article>
</template>

<script>
export default {
  name: 'CardInformationTraffic',
  props: {
    title: {
      type: String,
      default: null
    },
    total: {
      type: Number,
      default: null
    },
    icon: {
      type: String,
      default: null
    },
    color: {
      type: String,
      default: null
    }
  }
}
</script>

<style lang="scss" scoped>
  .media {
    border-radius: 10px;
    box-shadow: 0 3px 45px 0 rgba(188, 188, 222, 0.27);
    background-color: #fff;
    padding: 1rem;
    align-items: center;
  }
  .media-content {
    overflow-x: inherit;
    min-width: 60%;
  }
  .media_title {
    font-family: 'Montserrat', sans-serif;
    font-size: 13px;
    font-weight: bold;
    line-height: 1.23;
    letter-spacing: 0.65px;
    color: #2f4265;
    margin-bottom: 5px;
  }
  .media_total {
    font-size: 25px;
    font-weight: bold;
    line-height: 1.2;
    letter-spacing: 0.25px;
  }
  ion-icon {
    font-size: 35px;
  }

  @media screen and (max-width: 768px) {
    .media_title {
      font-size: 12px;
    }
    .media_total {
      font-size: 23px;
    }
  }
</style>
